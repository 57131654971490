import { render, staticRenderFns } from "./addType.vue?vue&type=template&id=d37337aa"
import script from "./addType.vue?vue&type=script&lang=js"
export * from "./addType.vue?vue&type=script&lang=js"
import style0 from "./addType.vue?vue&type=style&index=0&id=d37337aa&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
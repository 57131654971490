<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-row>
          <b-col md="4" class="my-1">
            <h2><b>Hardware type</b></h2>
          </b-col>
          <b-col md="8" class="my-1">
            <b-form inline class="d-flex justify-content-end">
              <b-input-group class="input-group-merge" style="width: 40%">
                <b-form-input
                  id="basic-password1"
                  type="text"
                  placeholder="Search..."
                  v-model="filter"
                />
                <b-input-group-append is-text>
                  <feather-icon icon="SearchIcon" class="cursor-pointer" />
                </b-input-group-append>
              </b-input-group>

              <b-button
                v-if="hardwarePermission=='create' || hardwarePermission=='edit'"
                variant="primary"
                class="ml-1 shadow"
                v-b-modal.add-hw-type-modal
                ><feather-icon icon="PlusCircleIcon" size="14" /> เพิ่ม Type
              </b-button>
            </b-form>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <b-table
              style="font-size: 12px"
              class="shadow"
              small
              striped
              bordered
              hover
              responsive
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="visibleFields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              :busy="isLoading"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(hwTypeName)="row">
                <span @click="$router.push(`/setting/hardware/warranty/brand/${row.item.id}?typeName=${row.item.hwTypeName}`)" style="text-decoration: underline">{{ row.item.hwTypeName }}</span>
              </template>

              <template #cell(no)="row">
                <center>{{ row.index + 1 }}</center>
              </template>

              <template #cell(hwTypeStatus)="row">
                <center>
                  <span
                    v-if="row.item.hwTypeStatus == 1"
                    class="text-primary"
                  >
                    ใช้งานได้ปกติ</span
                  >
                  <span v-else class="text-danger"> ใช้งานไม่ได้</span>
                </center>
              </template>

              <template #cell(action)="row" v-if="hardwarePermission=='create' || hardwarePermission=='edit'">
                <center>
                  <b-button
                    variant="outline-secondary"
                    size="sm"
                    pill
                    v-b-modal.add-hw-type-modal
                    @click="onSelectItemForEdit(row.item)"
                  >
                    <feather-icon icon="EditIcon" class="cursor-pointer" />
                  </b-button>

                  <b-button variant="danger" class="ml-1" size="sm" pill @click="deleteHwType(row.item.id)">
                    <feather-icon icon="TrashIcon" class="cursor-pointer" />
                  </b-button>
                </center>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="2">
            <b-form-group class="mb-0">
              <label class="d-inline-block text-sm-left mr-50">Per page</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>

          <b-col cols="10" class="d-flex justify-content-end">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              variant="secondary"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <AddTypeModal :typeData="typeData" @emitSaveHwType="onSave"/> 
  </b-row>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BSpinner,
  BForm
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Field from "./field";

import AddTypeModal from "./component/modal/addType.vue";

//SERVICE
import HardwareService from "@/services/setting/hardware"
import WarrantyStockService from "@/services/stock/warranty"

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BForm,
    BSpinner,
    vSelect,
    AddTypeModal,
  },
  data() {
    return {
      perPage: 1000,
      pageOptions: [1000, 2000, 5000],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      fields: Field,
      items: [],    
      typeData:{   
        "hwTypeName": "",
        "hwTypeStatus": 1,
        "mode": "warranty"
      },
      isLoading:false
    };
  },


  computed :{
    hardwarePermission () {
      const permission = this.$store.getters["user/getPermissionPage"]("ST002")
      if(permission=="disable"){
        localStorage.removeItem("userData")
        window.location.href="/not-authorized"
      }
      return permission
    },    
    visibleFields() {
        return this.fields.filter(field => {
          if(this.$store.getters["user/getPermissionPage"]("ST002") !== "edit"){
            return field.key !== "action"
          }
          return field
        })
    } 
  },
  mounted() {
    this.getHardwareType()
    
  },
  methods: {
    async getHardwareType(){
      this.isLoading = true
      const result = await HardwareService.GetHwTypeList("warranty","all=true")
      this.items = result.data
      this.totalRows = this.items.length;
      this.isLoading = false
    },
    async onSave(){
      if(this.typeData.id){
        await this.updateHardwareType()
        this.typeData ={   
          hwTypeName: "",
          hwTypeStatus: 0,
          mode: "warranty"
        }
      }else{
        await this.insertHardwareType()
      }
    },
    async insertHardwareType(){
      const result = await HardwareService.InsertHwType(this.typeData)    
      if (result.status == "success") {
          if(result.data.status == "dupplicate"){
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "XIcon",
              variant: "danger",
              text: `ชื่อนี้มีการใช้งานแล้ว กรุณาเปลี่ยนชื่อประเภท`,
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "CheckIcon",
              variant: "success",
              text: `เพิ่มประเภทอุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareType()
          this.typeData = {   
            "hwTypeName": "",
            "hwTypeStatus": 0,
            "mode": "warranty"
          }
        }


      
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการเพิ่มประเภทอุปกรณ์`,
            },
          });
        }
      
    },
    async updateHardwareType(){
      const result = await HardwareService.UpdateHwType(this.typeData,this.typeData.id)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "CheckIcon",
              variant: "success",
              text: `แก้ไขประเภทอุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareType()         
       
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการแก้ไขประเภทอุปกรณ์`,
            },
          });
        }
    },
    async deleteHwType(hwTypeId){
      const isConfirm = confirm("คุณต้องการลบรายการนี้หรือไม่ ?")
      if(!isConfirm) return false;

      const resultCheck = await WarrantyStockService.CheckItemBeforeDelete("hwTypeId", hwTypeId)
      if(resultCheck.data.hasData){
        this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `ประเภทอุปกรณ์`,
              icon: "CheckIcon",
              variant: "danger",
              text: `ไม่สามารถลบได้ เนื่องจากรายการนี้มีการใช้งานจากส่วนอื่นแล้ว`,
            },
          });

          return
      }

      const result = await HardwareService.DeleteHwType(hwTypeId)
      if (result.status == "success") {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "CheckIcon",
              variant: "success",
              text: `ลบประเภทอุปกรณ์เรียบร้อย`,
            },
          });
          this.getHardwareType()         
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Warranty`,
              icon: "XIcon",
              variant: "danger",
              text: `พบปัญหาในการลบประเภทอุปกรณ์`,
            },
          });
        }
    },
    onSelectItemForEdit(item){
      this.typeData = item

    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style scoped>
.table-hover tbody tr:hover {
  background-color: aqua !important;
}
</style>

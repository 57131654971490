<template>
  <div>
    <b-modal
      ref="add-hw-type-modal"
      id="add-hw-type-modal"
      title="เพิ่ม Type"
      size="sm"
      hide-footer
    >
      <b-card-text>
        <label>Type</label>
        <b-form-input id="smallInput" size="sm" placeholder="POS" class="mb-1" v-model.trim="typeData.hwTypeName" />

        <label>สถานะ</label>
        <b-form-group>
          <b-form-radio-group
            v-model="typeData.hwTypeStatus"
            :options="statusOptions"            
            name="radio-inline"
          />
        </b-form-group>

        <!--- START (BTN SUBMIT) --->
        <b-row class="mt-2">
          <b-col md="12" class="d-flex justify-content-end">
            <b-button variant="primary" size="sm" @click="save" >บันทึก</b-button>
          </b-col>
        </b-row>
        <!--- END (BTN SUBMIT) --->
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  VBModal,
  BCardText,
  BRow,
  BCol,
  BFormInput,  
  BFormRadioGroup,
  BFormGroup,
  BButton 
} from "bootstrap-vue";


export default {
  props:['typeData'],
  components: {
    BCardText,
    BRow,
    BCol,
    BFormInput,    
    BFormRadioGroup,
    BFormGroup,
    BButton
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      statusSelected: 'เปิด',
      statusOptions: [
        { text: 'เปิด', value: 1 },
        { text: 'ปิด', value: 0 },        
      ], 
    };
  },
  methods: {
    save() {
      if(!this.typeData.hwTypeName){
        alert("กรุณากรอกชื่อประเภท")
        return
      }

      this.$emit("emitSaveHwType")
      this.$refs["add-hw-type-modal"].hide();
    },
  },
};
</script>

<style>
@import "./style.css";
</style>
